<template>
  <section>
    <div class="text-h5 ml-2 mb-5">Exhibits</div>
    <v-row>
      <v-col cols="12" xl="9" lg="11">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  EXHIBIT TITLE
                </th>
                <th class="text-left">
                  STATUS
                </th>
                <th class="text-left">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in exhibits"
                :key="item.id"
              >
                <td>{{ item.lesson.title }}</td>
                <td>{{ item.status ? 'Active' : 'Not Active' }}</td>
                <td >
                  <v-btn class="button" :disabled="!item.status"
                  @click="$router.push({name: 'Student Exhibit Material', 
                  params: { uuid: item.uuid }})"
                  text 
                  link
                  color="info">Play</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    headers: [
      {
        text: 'EXHIBIT NAME',
        align: 'start',
        value: 'nam'
      },
    ]
  }),
  computed: {
    ...mapState('student', {
      exhibits: (state) => state.exhibits
    })
  },
  
  mounted() {
    this.getExhibits()
  },
  methods: {
    ...mapActions('student', [
      'getExhibits'
    ]),

  }
}
</script>
